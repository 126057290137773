import {ParsedUrlQueryInput} from "querystring";

export const getHostWithProtocol = () => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN;
  if (!domain) throw new Error("Unable to get domain from env.");
  return domain.includes("localhost") ? "http://" : "https://" + domain;
};

export const parseSearchParams = (searchParams: URLSearchParams) =>
  Array.from(searchParams.entries()).reduce<ParsedUrlQueryInput>(
    (acc, [key, value]) => ({...acc, [key]: value}),
    {},
  );

/**
 * When using this function, consider that it can throw errors if given a bad input to the URL constructor.
 */
export const parseHrefForLink = (
  href: string,
): {pathname: string; query: ParsedUrlQueryInput} | string => {
  const url = new URL(href);

  return url.host === process.env.NEXT_PUBLIC_DOMAIN
    ? {
        pathname: url.pathname,
        query: parseSearchParams(url.searchParams),
      }
    : url.toString();
};
